.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #2c3e50; /* Dark navy background for exclusivity */
  border-radius: 8px;
  margin-top: 30px;
}

.uploadInstructions {
  background-color: #1abc9c; /* Bright teal for an energetic feel */
  color: #ffffff; /* White text color */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 1.1rem;
  max-width: 600px; /* Limit width for better readability */
}

.uploadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Space between elements */
  width: 100%;
}

.fileInput {
  background-color: #34495e; /* Darker blue-gray for contrast */
  color: #ffffff;
  width: 100%;
  max-width: 400px; /* Limit width */
  border-radius: 4px;
  padding: 10px;
}

.uploadButton {
  background-color: #e74c3c; /* Vivid red for the upload button */
  color: #ffffff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.uploadButton:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.uploadButton:disabled {
  background-color: #95a5a6; /* Gray out the button when disabled */
  color: #bdc3c7;
}