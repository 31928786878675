/* SignUp.css */

.signup-container {
    max-width: 400px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .signup-title {
    margin-bottom: 24px;
    color: #003D3D;
    font-size: 2rem;
  }
  
  .signup-description {
    margin-bottom: 32px;
    color: #555;
    font-size: 1.1rem;
  }
  
  .signup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .signup-input {
    background-color: #e0e0e0;
    border-radius: 4px;
    font-size: 1.1rem;
  }
  
  .signup-error {
    color: red;
    margin-top: 16px;
  }
  
  .signup-button {
    margin-top: 24px;
    color: white;
    font-weight: bold;
    text-transform: none;
    font-size: 1.1rem;
  }
  
  .signup-button-primary {
    background-color: #008080;
  }
  
  .signup-button-primary:hover {
    background-color: #006666;
  }
  
  .signup-button-google {
    background-color: #E57373;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  
  .signup-button-google:hover {
    background-color: #D32F2F;
  }
  
  .signup-footer {
    margin-top: 32px;
    font-size: 1rem;
  }
  
  .signup-footer-link {
    color: #008080;
    text-decoration: underline;
  }
  