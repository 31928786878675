/* SpeedControlStyles.module.css */

.speedControlContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  
  .speedButton {
    border-radius: 50%; /* Make buttons round */
    min-width: 40px;
    min-height: 40px;
    padding: 0;
    margin: 0 5px;
  }
  
  .speedButton:hover {
    background-color: #ffa726; /* Hover color (lighter orange) */
  }
  
  .active {
    background-color: #ff9800; /* Active button color (orange) */
    color: #ffffff;
  }
  
  .inactive {
    background-color: #ffffff; /* Inactive button color (white) */
    color: #ff9800;
    border: 2px solid #ff9800;
  }
  
  .inactive:hover {
    background-color: #ffe0b2; /* Hover color for inactive buttons */
  }
  