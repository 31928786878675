.bloc-container {
  padding: 20px;
  background-color: #e0f2f1; /* Light teal background */
  color: #004d40; /* Dark teal for text */
  font-family: 'Arial', sans-serif;
}

.bloc-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #00796b; /* Teal color for the title */
  margin-bottom: 10px;
  text-align: center;
}

.bloc-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #004d40;
}

/* Video and buttons container */
.video-and-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container for the buttons and game banners */
.game-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure full width for mobile */
}

/* Responsive video container */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #000; /* Black background */
  border-radius: 10px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Score display */
.score-display {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: #00796b;
}

/* Button styles */
.notation-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%; /* Full width for buttons */
  margin-top: 10px;
}

.notation-button {
  background-color: #00796b; /* Teal button color */
  color: white;
  border: none;
  border-radius: 50px; /* Rounded buttons */
  padding: 12px 24px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.notation-button:hover {
  background-color: #f58220; /* Orange hover effect */
  transform: scale(1.05);
}

.notation-button:active {
  background-color: #f57c00;
  transform: scale(0.98);
}

/* Result message */
.result-message {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 10px; /* Added margin-top to separate it from the buttons */
  color: #004d40;
}

.game-finished-banner {
  text-align: center;
  color: #00796b;
}

.reset-button {
  background-color: #f57c00;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.reset-button:hover {
  background-color: #e65100;
}

/* Desktop layout: video on the left, controls on the right, buttons vertical */
@media (min-width: 768px) {
  .video-and-buttons {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .video-container {
    width: 70%; /* Video takes 70% of the width */
    padding-bottom: 40%;
  }

  .game-controls {
    width: 30%; /* Controls take 30% of the width */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  /* Make buttons vertical on desktop */
  .notation-buttons {
    flex-direction: column;
    justify-content: space-around;
    gap: 10px; /* Space between buttons */
  }
}

/* Mobile layout: buttons below the video, and horizontal */
@media (max-width: 767px) {
  .video-and-buttons {
    flex-direction: column;
  }

  .video-container {
    width: 100%; /* Ensure full width of the video container on mobile */
    height: auto; /* Let the video container expand to fit the video */
    padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
  }

  /* Make buttons horizontal on mobile */
  .notation-buttons {
    flex-direction: row;
    justify-content: space-around;
  }
}

/* Quiz finished banner */
.quiz-finished-banner {
  background-color: #f5f5f5;
  border: 2px solid #00796b;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
}

.quiz-finished-banner h2 {
  color: #00796b;
  font-size: 2rem;
  margin-bottom: 10px;
}

.quiz-finished-banner .score {
  color: #f57c00;
  font-size: 2rem;
  font-weight: bold;
}

.reset-quiz-button {
  background-color: #00796b;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.reset-quiz-button:hover {
  background-color: #004d40;
}

/* Game rules section */
.game-rules {
  background-color: #004d40; /* Teal background */
  border-radius: 10px;
  padding: 20px;
  color: #ffffff; /* White text for contrast */
  font-family: 'Arial', sans-serif;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.rules-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #f58220; /* Orange for the title */
  margin-bottom: 15px;
}

.rules-intro {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff; /* White for body text */
}

.rules-list {
  list-style-type: decimal;
  margin-left: 20px;
  color: #ffffff; /* White for list text */
}

.rules-list li {
  margin-bottom: 10px;
}

.position-list {
  list-style-type: none; /* No bullets for position list */
  padding-left: 0;
  margin-left: 10px;
}

.position-list li {
  margin-bottom: 5px;
  font-size: 1.2rem;
  color: #f58220; /* Orange for emphasis on positions */
}

.good-luck {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: #f58220; /* Orange for emphasis */
}

@media (min-width: 768px) {
  .game-rules {
    padding: 30px;
  }

  .rules-title {
    font-size: 2rem;
  }

  .rules-intro,
  .good-luck {
    font-size: 1.5rem;
  }

  .rules-list li {
    font-size: 1.3rem;
  }

  .position-list li {
    font-size: 1.2rem;
  }
}