/* BlocItemStyles.module.css */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #00897b;
  gap: 20px;
}

.contentContainer {
  display: flex;
  flex-direction: column; /* Ensure it stacks elements vertically */
  width: 100%;
  gap: 20px;
}

.videoContainerWrapper,
.prosaContainer {
  width: 100%; /* Make it take full width on all screen sizes */
}

@media (min-width: 768px) {
  .contentContainer {
    flex-direction: column; /* Keep column direction for full-width layout */
    gap: 20px;
  }

  .videoContainerWrapper,
  .prosaContainer {
    width: 100%; /* Full width for desktop */
  }
}

.prosaContainer {
  width: 100%;
  height: 200px; /* Fixed height */
  overflow-y: auto; /* Enable scrolling for overflow */
  padding: 15px;
  background-color: #004d40; /* Subtle background */
  border-radius: 12px;
  color: #ffffff;
  font-size: 16px; /* Default font size */
  line-height: 1.5; /* Improve readability */
  scrollbar-width: thin; /* Custom scrollbar for modern browsers */
  scrollbar-color: #80cbc4 #003d33; /* Scrollbar colors */
}

/* Scrollbar for Webkit-based browsers */
.prosaContainer::-webkit-scrollbar {
  width: 8px;
}

.prosaContainer::-webkit-scrollbar-thumb {
  background-color: #80cbc4;
  border-radius: 4px;
}

.prosaContainer::-webkit-scrollbar-track {
  background-color: #003d33;
}

.buttonsContainer {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .contentContainer {
    flex-direction: row;
    gap: 20px;
  }

  .videoContainerWrapper,
  .prosaContainer {
    width: 50%;
  }
}

.speedControl {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.toolsDescriptionContainer {
  background-color: #003d33; /* Darker teal for a grounded look */
  padding: 20px;
  border-radius: 12px; /* Softer rounded edges */
  color: #e0f2f1; /* Light teal text for better readability */
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.toolsDescriptionContainer:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

.toolsDescriptionContainer h3 {
  color: #ff7043; /* Warmer orange accent for the title */
  font-size: 1.6rem; /* Slightly larger font size */
  margin-bottom: 15px;
  text-align: center;
}

.toolsDescriptionContainer ul {
  list-style-type: disc; /* Use disc for list style */
  padding-left: 20px;
  color: #e0f2f1; /* Light teal text for list items */
}

.toolsDescriptionContainer li {
  margin-bottom: 12px;
  font-size: 1.1rem;
  line-height: 1.5; /* Improve readability with more spacing between lines */
}

.toolsDescriptionContainer li strong {
  color: #ff7043; /* Warmer orange accent for important text */
}

.toolsDescriptionContainer li:hover {
  color: #4db6ac; /* Lighter teal for hover effect on list items */
}

.beat-selector {
  margin-bottom: 1rem; /* Add spacing below each selector */
}

.beat-selector .MuiFormControl-root {
  background-color: #004d40; /* Dark teal background for the dropdown */
  border-radius: 8px; /* Rounded corners for a polished look */
  padding: 8px; /* Internal padding for a larger click area */
  border: none; /* Remove the thin black border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.beat-selector .MuiFormControl-root:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle hover effect */
}

.beat-selector .MuiInputLabel-root {
  color: #e0f7fa; /* Light teal color for the label */
  font-weight: 500; /* Slightly bold label text */
}

.beat-selector .MuiInputLabel-root.Mui-focused {
  color: #b2ebf2; /* Brighter light teal when focused */
}

.beat-selector .MuiSelect-root {
  font-size: 16px; /* Adjust font size for better readability */
  color: #e0f7fa; /* Light teal text for the dropdown */
  background-color: #004d40; /* Dark teal background for the dropdown */
  border-radius: 4px; /* Smooth corners */
  padding: 8px; /* Add padding inside the dropdown */
}

.beat-selector .MuiSelect-root:hover {
  background-color: #003d33; /* Slightly darker teal on hover */
}

.beat-selector .MuiMenuItem-root {
  font-size: 15px; /* Font size for dropdown items */
  padding: 10px 16px; /* Spacing for better click area */
  color: #e0f7fa; /* Light teal text for items */
}

.beat-selector .MuiMenuItem-root:hover {
  background-color: rgba(224, 247, 250, 0.1); /* Subtle light teal highlight on hover */
}

.beat-selector .MuiMenuItem-root.Mui-selected {
  background-color: #80cbc4; /* Highlighted teal for selected item */
  color: #003d33; /* Dark teal for selected text */
  font-weight: bold; /* Make the selected item stand out */
}

.beat-selector .MuiMenuItem-root.Mui-selected:hover {
  background-color: #4db6ac; /* Darker teal for selected item on hover */
}

.beat-selector-end {
  margin-bottom: 20px; /* Add spacing below the end selector */
}
