.matchesContainer {
    padding: 20px;
    background-color: #003d33; /* Dark teal background */
    color: #e0f2f1; /* Light teal text */
    border-radius: 8px;
  }
  
  .matchCard {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #004d40;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }