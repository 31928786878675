.ar-swipe-walkthrough {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  canvas {
    display: block;
  }
  