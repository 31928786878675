/* SignIn.css */

.signin-container {
  max-width: 400px;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-title {
  margin-bottom: 24px; /* Increased space below the title */
  color: #003D3D;
  font-size: 2rem;
}

.signin-form {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px; /* Adds consistent spacing between all form elements */
}

.signin-input {
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 1.1rem;
}

.signin-link {
  text-align: right;
  display: block;
  margin-bottom: 24px; /* Increased space below the link */
  color: #FF6347;
  text-decoration: underline;
  font-size: 1rem;
}

/* Updated button styles with extra spacing */
.signin-button {
  margin-top: 16px;
  color: white;
  font-weight: bold;
  text-transform: none;
  font-size: 1.1rem;
}

.signin-button-primary {
  background-color: #007777;
  margin-bottom: 16px; /* Increased space between the Sign In and Google buttons */
}

.signin-button-primary:hover {
  background-color: #005555;
}

.signin-button-google {
  background-color: #FF6347;
  margin-bottom: 24px; /* Increased space below the Google button */
}

.signin-button-google:hover {
  background-color: #FF4500;
}

.signin-error {
  color: red;
  margin-top: 16px;
}

.signin-footer {
  margin-top: 32px; /* Adds space above the footer */
  text-align: center;
  font-size: 1.1rem;
}

.signin-footer-link {
  color: #007777;
  text-decoration: underline;
}
