.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .subscribe-button {
    background-color: #28a745;
    color: white;
  }
  
  .unsubscribe-button {
    background-color: #dc3545;
    color: white;
  }
  
  button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .error-text {
    color: red;
    margin-top: 20px;
    font-weight: bold;
  }
  